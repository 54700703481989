import sound from "../assets/audio/success.mp3";

export const cards = document.querySelectorAll(".memory-card");
export let points = 0;
export let hasFlippedCard = false;
export let lockBoard = false;
export let firstCard, secondCard;

export function flipCard() {
  if (lockBoard) return;
  if (this === firstCard) return;

  this.classList.add("flip");

  if (!hasFlippedCard) {
    hasFlippedCard = true;
    firstCard = this;

    return;
  }

  secondCard = this;
  checkForMatch();
}

export async function checkForMatch() {
  const isMatch = firstCard.dataset.color === secondCard.dataset.color;
  if (isMatch) {
    await makeNumberVisible();
    disableCards();
    points++;
    if (points === 4) {
      Alpine.store("game_states").game_3.completed = true;
      if (Alpine.store("game_states").is_completed("game_3")) {
        const success_sound = new Audio(sound);
        success_sound.play();
      }
    }
  } else {
    unflipCards();
  }
}

export function makeNumberVisible() {
  lockBoard = true;
  return new Promise((resolve) => {
    setTimeout(() => {
      firstCard.children[2].children[1].style.display = "block";
      secondCard.children[2].children[1].style.display = "block";
      lockBoard = false;
      resolve(); // Resolve the promise after visibility is set
    }, 500);
  });
}

export function disableCards() {
  if (firstCard && secondCard) {
    firstCard.removeEventListener("click", flipCard);
    secondCard.removeEventListener("click", flipCard);
  }
  resetBoard();
}

export function unflipCards() {
  lockBoard = true;

  setTimeout(() => {
    firstCard.classList.remove("flip");
    secondCard.classList.remove("flip");

    resetBoard();
  }, 1500);
}

export function resetBoard() {
  [hasFlippedCard, lockBoard] = [false, false];
  [firstCard, secondCard] = [null, null];
}
