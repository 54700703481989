let DragDropTouch;
!(function (t) {
  "use strict";
  let e = (function () {
    function t() {
      (this._dropEffect = "move"),
        (this._effectAllowed = "all"),
        (this._data = {});
    }
    return (
      Object.defineProperty(t.prototype, "dropEffect", {
        get: function () {
          return this._dropEffect;
        },
        set: function (t) {
          this._dropEffect = t;
        },
        enumerable: !0,
        configurable: !0,
      }),
      Object.defineProperty(t.prototype, "effectAllowed", {
        get: function () {
          return this._effectAllowed;
        },
        set: function (t) {
          this._effectAllowed = t;
        },
        enumerable: !0,
        configurable: !0,
      }),
      Object.defineProperty(t.prototype, "types", {
        get: function () {
          return Object.keys(this._data);
        },
        enumerable: !0,
        configurable: !0,
      }),
      (t.prototype.clearData = function (t) {
        null !== t ? delete this._data[t.toLowerCase()] : (this._data = {});
      }),
      (t.prototype.getData = function (t) {
        let e = t.toLowerCase(),
          i = this._data[e];
        return (
          "text" === e && null == i && (i = this._data["text/plain"]), i || ""
        );
      }),
      (t.prototype.setData = function (t, e) {
        this._data[t.toLowerCase()] = e;
      }),
      (t.prototype.setDragImage = function (t, e, s) {
        let o = i._instance;
        (o._imgCustom = t), (o._imgOffset = { x: e, y: s });
      }),
      t
    );
  })();
  t.DataTransfer = e;
  let i = (function () {
    function t() {
      if (((this._lastClick = 0), t._instance))
        throw "DragDropTouch instance already created.";
      let e = !1;
      if (
        (document.addEventListener("test", function () {}, {
          get passive() {
            return (e = !0), !0;
          },
        }),
        navigator.maxTouchPoints)
      ) {
        let i = document,
          s = this._touchstart.bind(this),
          o = this._touchmove.bind(this),
          n = this._touchend.bind(this),
          r = !!e && { passive: !1, capture: !1 };
        i.addEventListener("touchstart", s, r),
          i.addEventListener("touchmove", o, r),
          i.addEventListener("touchend", n),
          i.addEventListener("touchcancel", n);
      }
    }
    return (
      (t.getInstance = function () {
        return t._instance;
      }),
      (t.prototype._touchstart = function (e) {
        let i = this;
        if (this._shouldHandle(e)) {
          this._reset();
          let s = this._closestDraggable(e.target);
          s &&
            !this._dispatchEvent(e, "mousemove", e.target) &&
            !this._dispatchEvent(e, "mousedown", e.target) &&
            ((this._dragSource = s),
            (this._ptDown = this._getPoint(e)),
            (this._lastTouch = e),
            setTimeout(function () {
              i._dragSource === s &&
                null === i._img &&
                i._dispatchEvent(e, "contextmenu", s) &&
                i._reset();
            }, t._CTXMENU),
            t._ISPRESSHOLDMODE &&
              (this._pressHoldInterval = setTimeout(function () {
                (i._isDragEnabled = !0), i._touchmove(e);
              }, t._PRESSHOLDAWAIT)));
        }
      }),
      (t.prototype._touchmove = function (t) {
        if (this._shouldCancelPressHoldMove(t)) {
          this._reset();
          return;
        }
        if (this._shouldHandleMove(t) || this._shouldHandlePressHoldMove(t)) {
          let e = this._getTarget(t);
          if (this._dispatchEvent(t, "mousemove", e)) {
            (this._lastTouch = t), t.preventDefault();
            return;
          }
          if (this._dragSource && !this._img && this._shouldStartDragging(t)) {
            if (
              this._dispatchEvent(
                this._lastTouch,
                "dragstart",
                this._dragSource
              )
            ) {
              this._dragSource = null;
              return;
            }
            this._createImage(t), this._dispatchEvent(t, "dragenter", e);
          }
          this._img &&
            ((this._lastTouch = t),
            t.preventDefault(),
            this._dispatchEvent(t, "drag", this._dragSource),
            e !== this._lastTarget &&
              (this._dispatchEvent(
                this._lastTouch,
                "dragleave",
                this._lastTarget
              ),
              this._dispatchEvent(t, "dragenter", e),
              (this._lastTarget = e)),
            this._moveImage(t),
            (this._isDropZone = this._dispatchEvent(t, "dragover", e)));
        }
      }),
      (t.prototype._touchend = function (t) {
        if (this._shouldHandle(t)) {
          if (this._dispatchEvent(this._lastTouch, "mouseup", t.target)) {
            t.preventDefault();
            return;
          }
          this._img ||
            ((this._dragSource = null),
            this._dispatchEvent(this._lastTouch, "click", t.target),
            (this._lastClick = Date.now())),
            this._destroyImage(),
            this._dragSource &&
              (0 > t.type.indexOf("cancel") &&
                this._isDropZone &&
                this._dispatchEvent(this._lastTouch, "drop", this._lastTarget),
              this._dispatchEvent(this._lastTouch, "dragend", this._dragSource),
              this._reset());
        }
      }),
      (t.prototype._shouldHandle = function (t) {
        return t && !t.defaultPrevented && t.touches && t.touches.length < 2;
      }),
      (t.prototype._shouldHandleMove = function (e) {
        return !t._ISPRESSHOLDMODE && this._shouldHandle(e);
      }),
      (t.prototype._shouldHandlePressHoldMove = function (e) {
        return (
          t._ISPRESSHOLDMODE &&
          this._isDragEnabled &&
          e &&
          e.touches &&
          e.touches.length
        );
      }),
      (t.prototype._shouldCancelPressHoldMove = function (e) {
        return (
          t._ISPRESSHOLDMODE &&
          !this._isDragEnabled &&
          this._getDelta(e) > t._PRESSHOLDMARGIN
        );
      }),
      (t.prototype._shouldStartDragging = function (e) {
        let i = this._getDelta(e);
        return (
          i > t._THRESHOLD || (t._ISPRESSHOLDMODE && i >= t._PRESSHOLDTHRESHOLD)
        );
      }),
      (t.prototype._reset = function () {
        this._destroyImage(),
          (this._dragSource = null),
          (this._lastTouch = null),
          (this._lastTarget = null),
          (this._ptDown = null),
          (this._isDragEnabled = !1),
          (this._isDropZone = !1),
          (this._dataTransfer = new e()),
          clearInterval(this._pressHoldInterval);
      }),
      (t.prototype._getPoint = function (t, e) {
        return (
          t && t.touches && (t = t.touches[0]),
          { x: e ? t.pageX : t.clientX, y: e ? t.pageY : t.clientY }
        );
      }),
      (t.prototype._getDelta = function (e) {
        if (t._ISPRESSHOLDMODE && !this._ptDown) return 0;
        let i = this._getPoint(e);
        return Math.abs(i.x - this._ptDown.x) + Math.abs(i.y - this._ptDown.y);
      }),
      (t.prototype._getTarget = function (t) {
        let e = this._getPoint(t),
          i = document.elementFromPoint(e.x, e.y);
        for (; i && "none" == getComputedStyle(i).pointerEvents; )
          i = i.parentElement;
        return i;
      }),
      (t.prototype._createImage = function (e) {
        this._img && this._destroyImage();
        let i = this._imgCustom || this._dragSource;
        if (
          ((this._img = i.cloneNode(!0)),
          this._copyStyle(i, this._img),
          (this._img.style.top = this._img.style.left = "-9999px"),
          !this._imgCustom)
        ) {
          let s = i.getBoundingClientRect(),
            o = this._getPoint(e);
          (this._imgOffset = { x: o.x - s.left, y: o.y - s.top }),
            (this._img.style.opacity = t._OPACITY.toString());
        }
        this._moveImage(e), document.body.appendChild(this._img);
      }),
      (t.prototype._destroyImage = function () {
        this._img &&
          this._img.parentElement &&
          this._img.parentElement.removeChild(this._img),
          (this._img = null),
          (this._imgCustom = null);
      }),
      (t.prototype._moveImage = function (t) {
        let e = this;
        requestAnimationFrame(function () {
          if (e._img) {
            let i = e._getPoint(t, !0),
              s = e._img.style;
            (s.position = "absolute"),
              (s.pointerEvents = "none"),
              (s.zIndex = "999999"),
              (s.left = Math.round(i.x - e._imgOffset.x) + "px"),
              (s.top = Math.round(i.y - e._imgOffset.y) + "px");
          }
        });
      }),
      (t.prototype._copyProps = function (t, e, i) {
        for (let s = 0; s < i.length; s++) {
          let o = i[s];
          t[o] = e[o];
        }
      }),
      (t.prototype._copyStyle = function (e, i) {
        if (
          (t._rmvAtts.forEach(function (t) {
            i.removeAttribute(t);
          }),
          e instanceof HTMLCanvasElement)
        ) {
          let s = e,
            o = i;
          (o.width = s.width),
            (o.height = s.height),
            o.getContext("2d").drawImage(s, 0, 0);
        }
        let n = getComputedStyle(e);
        for (let r = 0; r < n.length; r++) {
          let a = n[r];
          0 > a.indexOf("transition") && (i.style[a] = n[a]);
        }
        i.style.pointerEvents = "none";
        for (let h = 0; h < e.children.length; h++)
          this._copyStyle(e.children[h], i.children[h]);
      }),
      (t.prototype._setOffsetAndLayerProps = function (t, e) {
        let i;
        void 0 === t.offsetX &&
          ((i = e.getBoundingClientRect()),
          (t.offsetX = t.clientX - i.x),
          (t.offsetY = t.clientY - i.y)),
          void 0 === t.layerX &&
            ((i = i || e.getBoundingClientRect()),
            (t.layerX = t.pageX - i.left),
            (t.layerY = t.pageY - i.top));
      }),
      (t.prototype._dispatchEvent = function (e, i, s) {
        if (e && s) {
          let o = new Event(i, { bubbles: !0, cancelable: !0 }),
            n = e.touches ? e.touches[0] : e;
          return (
            (o.button = 0),
            (o.which = o.buttons = 1),
            this._copyProps(o, e, t._kbdProps),
            this._copyProps(o, n, t._ptProps),
            this._setOffsetAndLayerProps(o, s),
            (o.dataTransfer = this._dataTransfer),
            s.dispatchEvent(o),
            o.defaultPrevented
          );
        }
        return !1;
      }),
      (t.prototype._closestDraggable = function (t) {
        for (; t; t = t.parentElement) if (t.draggable) return t;
        return null;
      }),
      t
    );
  })();
  (i._instance = new i()),
    (i._THRESHOLD = 5),
    (i._OPACITY = 0.5),
    (i._DBLCLICK = 500),
    (i._CTXMENU = 900),
    (i._ISPRESSHOLDMODE = !1),
    (i._PRESSHOLDAWAIT = 400),
    (i._PRESSHOLDMARGIN = 25),
    (i._PRESSHOLDTHRESHOLD = 0),
    (i._rmvAtts = "id,class,style,draggable".split(",")),
    (i._kbdProps = "altKey,ctrlKey,metaKey,shiftKey".split(",")),
    (i._ptProps =
      "pageX,pageY,clientX,clientY,screenX,screenY,offsetX,offsetY".split(",")),
    (t.DragDropTouch = i);
})(DragDropTouch || (DragDropTouch = {}));
