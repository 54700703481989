import Alpine from "alpinejs";
import explosionSound from "./assets/audio/explosion.mp3";
import beepSound from "./assets/audio/beep.mp3";

const explosion = new Audio(explosionSound);

// Importing game logic.
import * as game1 from "./js/game1";
import * as game3 from "./js/game3";
import * as game4 from "./js/game4";

// This file makes sure the HTML drag and drop API is supported on touch devices.
import "./js/draganddrop";

window.Alpine = Alpine;

Alpine.store("textbox_open", false);

Alpine.store("game_states", {
  game_1: {
    active: false,
    completed: false,
  },
  game_2: {
    active: false,
    completed: false,
  },
  game_3: {
    active: false,
    completed: false,
  },
  game_4: {
    active: false,
    completed: false,
  },
  is_completed(game_id) {
    return this[game_id].completed;
  },
  toggle_active(game_id) {
    this[game_id].active = !this[game_id].active;
  },
  go_to_next_game(game_id) {
    if (game_id === "game_4") {
      return;
    }
    const game_ids = Object.keys(this);
    const game_index = game_ids.indexOf(game_id);
    const next_game_id = game_ids[game_index + 1];
    this.toggle_active(game_id);
    this.toggle_active(next_game_id);
  },
});

Alpine.store("video_modal_open", true);

Alpine.store("help_modal_open", false);
Alpine.store("timer", "10:00");
Alpine.store("game_over", false);
Alpine.store("game_won", false);
Alpine.store("play_again_button_visible", false);

Alpine.start();

// Countdown logic
function startCountdown(totalTime, countdownElement, countdownNumberElement) {
  let countdown = totalTime; // Initialize countdown
  const circumference = 487; // Circle circumference

  function updateCircle(countdownElement) {
    const percentage = countdown / totalTime;
    const offset = circumference * (1 - percentage);
    countdownElement.style.strokeDashoffset = offset;

    let minutes = Math.floor(countdown / 60);
    let seconds = countdown % 60;
    const parsedTime = `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    Alpine.store("timer", parsedTime);
  }

  let timerInterval = setInterval(function () {
    countdown--;

    if (countdown === 60) {
      countdownNumberElement.style.color = "red";
      countdownElement.style.stroke = "red";
    }

    if (countdown === 14) {
      const beep = new Audio(beepSound);
      beep.play();
    }

    if (countdown === 0) {
      clearInterval(timerInterval);
      explosion.play();
      Alpine.store("game_over", true);

      const failureVideo = document.getElementById("failure-video");
      failureVideo.play();
    }

    updateCircle(countdownElement);

    if (Alpine.store("game_over") || Alpine.store("game_won")) {
      clearInterval(timerInterval);
    }
  }, 1000);

  countdownElement.style.strokeDasharray = circumference;
  countdownElement.style.strokeDashoffset = 0;
}

// When the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  const playButton = document.getElementById("play-button");

  playButton.addEventListener("click", () => {
    // Hide the button and play the video
    playButton.style.display = "none";
    const video = document.getElementById("video");
    video.play();

    video.addEventListener("ended", () => {
      Alpine.store("video_modal_open", false);
      video.currentTime = 0;
      Alpine.store("game_states").toggle_active("game_1");

      // Start countdown
      startCountdown(
        600,
        document.getElementById("countdown-circle"),
        document.getElementById("countdown-number")
      );
    });
  });

  game1.initializeGame();

  document
    .getElementById("g1_check")
    .addEventListener("click", game1.checkGameCompletion);
  document
    .getElementById("g1_reset")
    .addEventListener("click", game1.resetGame);
});

// Game 3 logic
game3.cards.forEach((card) => card.addEventListener("click", game3.flipCard));
