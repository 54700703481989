// game1.js
export const draggable_elements = document.getElementsByClassName("g1_drag");
export const dropzone_elements = document.getElementsByClassName("g1_dropzone");
export const dragzone = document.getElementById("g1_dragzone");
import success_sound from "../assets/audio/success.mp3";
import failure_sound from "../assets/audio/failure.mp3";
export let selected;

export const colors = [
  { correct: "green", incorrect: "red" },
  { correct: "red", incorrect: "green" },
  { correct: "red", incorrect: "green" },
  { correct: "green", incorrect: "red" },
  { correct: "red", incorrect: "green" },
  { correct: "red", incorrect: "green" },
  { correct: "green", incorrect: "red" },
  { correct: "red", incorrect: "green" },
];

export function initializeGame() {
  for (const drag of draggable_elements) {
    drag.addEventListener("dragstart", (event) => {
      selected = event.target;
    });
  }

  for (let index = 0; index < dropzone_elements.length; index++) {
    const drop = dropzone_elements[index];

    drop.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    if (!drop.hasAttribute("data-block")) {
      drop.addEventListener("drop", (event) => {
        event.preventDefault();

        // If the dropzone already has one child, move it back to the dragzone
        if (drop.children.length === 1) {
          dragzone.appendChild(selected);
          return;
        }

        // Check if the selected element has the data-correct attribute
        if (selected && selected.hasAttribute("data-correct")) {
          drop.style.backgroundColor = colors[index]["correct"];
        } else {
          // Set to the single color
          drop.style.backgroundColor = colors[index]["incorrect"];
        }
        // Append the selected element to the dropzone
        drop.appendChild(selected);
      });
    }
  }
}

export function checkGameCompletion() {
  let completed = true;
  for (const drop of dropzone_elements) {
    if (drop.hasAttribute("data-block")) {
      continue;
    }
    if (
      drop.children.length !== 1 ||
      !drop.children[0].hasAttribute("data-correct")
    ) {
      completed = false;
      break;
    }
  }
  if (completed) {
    Alpine.store("game_states").game_1.completed = true;
    if (Alpine.store("game_states").is_completed("game_1")) {
      const success = new Audio(success_sound);
      success.play();
      Alpine.store("game_states").go_to_next_game("game_1");
      Alpine.store("textbox_open", true);
    }
  } else {
    const failure = new Audio(failure_sound);
    failure.play();
  }
}

export function resetGame() {
  for (const drop of dropzone_elements) {
    if (drop.children.length === 1) {
      dragzone.appendChild(drop.children[0]);
      drop.style.backgroundColor = "";
    }
  }
}
